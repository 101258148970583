import React, { Component } from "react";
import "./accordion.scss";

const Accordion = (props) => {
	const { id, label } = props;
	const [expanded, setExpanded] = React.useState(false);

	return (
		<div className="accordion">
			<button
				className="accordion__header"
				aria-controls={`content-${id}`}
				aria-expanded={expanded}
				onClick={() => setExpanded(!expanded)}
			>
				{expanded ? props.closeLabel : props.openLabel}
			</button>

			<div
				className="accordion__content"
				id={`content-${id}`}
				aria-hidden={!expanded}
			>
				<div className="accordion__content-inner">{props.children}</div>
			</div>
		</div>
	);
};

export default Accordion;
