import React from "react";
import "./search-bar.scss";

const SearchBar = (props) => {
	const { value, placeholder, onChange } = props;

	return (
		<div className={`search-bar`}>
			<div className={`search-bar__inner`}>
				<form className={`search-bar__form`}>
					<svg className="icon search-bar__icon">
						<use xlinkHref="#icon-magnifier" />
					</svg>
					<input
						type={`search`}
						className={`search-bar__input`}
						placeholder={placeholder}
						defaultValue={value}
						onChange={(e) => onChange(e.target.value.toLowerCase())}
					/>
				</form>
			</div>
		</div>
	);
};

SearchBar.defaultProps = {
	placeholder: "",
	value: "",
};

export default SearchBar;
