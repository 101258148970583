import React from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";

import { SINGLE_ASSESSMENT } from "../../graphql/queries";
import AssessmentStrategy from "./AssessmentStrategy";
import {
	LoadingIndicator,
	Button,
	GoalPrintBox,
	ErrorMessage,
	Section,
} from "../../components";

import { ResultGraph } from "../../layouts";
import { NotFoundPage } from "../../pages";
import { AppContext } from "../../context/AppContext";

const AssessmentResultsPage = (props) => {
	const { locale, appData, getContentByDocType } = React.useContext(
		AppContext
	);
	const { page, common_translations } = getContentByDocType("result_page");

	const { user_id } = appData.user;
	const { url_alias } = useParams();
	const { button_labels } = common_translations;
	const errors = common_translations?.error_messages[0] ?? null;
	const { loading, error, data } = useQuery(SINGLE_ASSESSMENT, {
		variables: { user_id, url_alias },
	});

	if (!page) return <NotFoundPage />;
	if (loading) return <LoadingIndicator />;
	if (error) return <ErrorMessage error={errors.general} />;
	const assessment = data.getAssessment;

	return (
		<div className="page__content">
			<div className="section section--white">
				<Button
					link={`/${locale}/tool/assessment/${assessment.url_alias}`}
					size={`small`}
					label={button_labels[0].back_to_assessment_board}
				/>
				<div className="print-only print-only-logo">
					<img src="/images/siat-logo.svg" alt="" />
				</div>
				<h2 className="page__title page__title--lowercase">
					{assessment.title}
				</h2>

				<ResultGraph goals={assessment.goals} />
			</div>

			<Section
				title={page.print_description_title ?? "Description"}
				description={assessment.description}
				print={true}
			/>

			{/* Strategic choices checkboxes and textarea */}
			<AssessmentStrategy
				strategy={assessment.strategy}
				assessment_id={assessment._id}
			/>

			<Button
				onClick={() => window.print()}
				position="center"
				color="black"
				icon={"print"}
				label={page.print_button_label ?? "Print"}
			/>

			<div>
				{assessment.goals.map((goal, i) => {
					return <GoalPrintBox {...goal} key={i} />;
				})}
			</div>
		</div>
	);
};

export default AssessmentResultsPage;
