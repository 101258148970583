import React from "react";
import { Link } from "react-router-dom";
import cx from "classnames";
import "./button.scss";

const Button = (props) => {
	const {
		label,
		color,
		size,
		href,
		link,
		onClick,
		opacity,
		state,
		icon,
		position,
		disabled,
	} = props;
	const classNames = cx(
		"button",
		color && "button--" + color,
		size && "button--" + size,
		state && "button--" + state,
		icon && "button--with-icon",
		position && "button--" + position
	);

	if (href) {
		return (
			<a
				href={href}
				className={classNames}
				data-opacity={opacity}
				disabled={disabled}
			>
				<span className={`button__inner`}>{label}</span>
			</a>
		);
	} else if (link) {
		return (
			<Link
				to={link}
				className={classNames}
				data-opacity={opacity}
				disabled={disabled}
			>
				<span className={`button__inner`}>{label}</span>
			</Link>
		);
	} else {
		return (
			<button
				className={classNames}
				onClick={onClick}
				data-opacity={opacity && opacity}
				disabled={disabled}
			>
				{icon && (
					<svg className="icon">
						<use xlinkHref="#icon-print" />
					</svg>
				)}
				<span className={`button__inner`}>{label}</span>
			</button>
		);
	}
};

Button.defaultProps = {
	label: "",
	opacity: "",
	disabled: false,
};

export default Button;
