import React from "react";
import { useParams } from "react-router-dom";
import { linkResolver } from "../../utils/prismic";
import { RichText } from "prismic-reactjs";
import { Layout, Footer } from "../../components";
import { NotFoundPage } from "..";
import { AppContext } from "../../context/AppContext";

const StandardPage = (props) => {
	const { page_uid } = useParams();
	const { getContentByDocType } = React.useContext(AppContext);
	const { page } = getContentByDocType(page_uid);

	if (!page) return <NotFoundPage />;

	return (
		<>
			<Layout
				title={RichText.asText(page.title)}
				width={`width-8`}
				preamble={RichText.asText(page.preamble)}
				backgroundColor={`white`}
			>
				{RichText.render(page.body, linkResolver)}

				{page.iframe_embed?.length > 0 &&
					page.iframe_embed[0].text !== "" && (
						<div
							className="iframe-embed"
							dangerouslySetInnerHTML={{
								__html: page.iframe_embed[0].text,
							}}
						/>
					)}
			</Layout>
			<Footer />
		</>
	);
};

export default StandardPage;
