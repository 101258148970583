import React from "react";
import { useMutation } from "@apollo/client";

import { ADD_OR_UPDATE_STRATEGY } from "../../graphql/mutations";
import { Form, Section } from "../../components";

import { AppContext } from "../../context/AppContext";

const AssessmentStrategy = (props) => {
	const { getContentByDocType } = React.useContext(AppContext);
	const { page, common_translations } = getContentByDocType("result_page");

	const [
		saveAssessmentStrategyFailed,
		setSaveAssessmentStrategyFailed,
	] = React.useState(false);
	const [
		saveAssessmentStrategyMessage,
		setSaveAssessmentStrategyMessage,
	] = React.useState("");
	const [strategy, setStrategy] = React.useState(props.strategy);

	const [addOrUpdateStrategyMutation] = useMutation(ADD_OR_UPDATE_STRATEGY);

	const saveAssessmentStrategy = async (e, formState) => {
		e.preventDefault();

		const { strategy } = formState;
		let questions = [];

		for (let key in formState) {
			if (key !== "strategy") {
				let obj = {};
				obj["id"] = key;
				obj["checked"] = formState[key];

				questions.push(obj);
			}
		}

		try {
			await addOrUpdateStrategyMutation({
				variables: {
					assessment_id: props.assessment_id,
					questions,
					strategy,
				},
			});

			setSaveAssessmentStrategyFailed(false);
			setSaveAssessmentStrategyMessage(
				common_translations?.success_messages[0].save_assessment ??
					"Assessment has been updated"
			);
			setStrategy({
				questions,
				strategy,
			});

			// Clear success message after a timeout
			setTimeout(() => {
				setSaveAssessmentStrategyFailed(false);
				setSaveAssessmentStrategyMessage("");
			}, 5000);
		} catch (error) {
			setSaveAssessmentStrategyFailed(true);
			setSaveAssessmentStrategyMessage(
				common_translations?.error_messages[0]?.save_assessment ??
					"Couldn't save assessment"
			);

			// Clear error message after a timeout
			setTimeout(() => {
				setSaveAssessmentStrategyFailed(false);
				setSaveAssessmentStrategyMessage("");
			}, 5000);
		}
	};

	const questionCheckboxFields = [];

	if (page.strategy_questions) {
		page.strategy_questions.forEach((q) => {
			if (strategy !== null) {
				strategy.questions.forEach((savedQ) => {
					if (savedQ.id === q.id) {
						q["checked"] = savedQ.checked;
					}
				});
			}

			questionCheckboxFields.push({
				type: "checkbox",
				required: false,
				id: q.id,
				label: q.label,
				checked: q.checked,
			});
		});
	}

	let defaultStrategyValue = strategy?.strategy ?? "";

	return (
		<div className="section">
			<div className="section__inner">
				<h2 className="section__title section__title--centered section__title--lowercase">
					{page.strategy_title}
				</h2>
				<p className="section__description">
					{page.strategy_description}
				</p>

				<Form
					initialState={strategy}
					fields={[
						{
							type: "fieldGroup",
							children: questionCheckboxFields,
						},
						{
							type: "textarea",
							required: false,
							id: "strategy",
							label: "",
							placeholder: page.motivation_placeholder,
							maxlength: 4000,
							modifier: "strategy",
							value: defaultStrategyValue,
						},
					]}
					error={saveAssessmentStrategyFailed}
					success={!saveAssessmentStrategyFailed}
					message={saveAssessmentStrategyMessage}
					submit={{
						label: page.save_motivation_label,
						modifier: "slim",
						handler: (e, formState) =>
							saveAssessmentStrategy(e, formState),
					}}
				/>

				{/* Only for print below */}
				{strategy && (
					<Section
						title={page.strategy_title_print}
						description={strategy.strategy}
						print={true}
					/>
				)}
			</div>
		</div>
	);
};

export default AssessmentStrategy;
