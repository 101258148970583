import React, { Component } from 'react';
import cx from 'classnames';
import './form.scss';

class Form extends Component {
    constructor(props) {
        super(props);

        this.state = this.setInitialState();
    }

    componentWillReceiveProps(nextProps) {
        const { fields } = nextProps;
        let state = {};

        /* 
        * Populate state with form field IDs and value, eg
        * {
        *   strategy: 'value of strategy field'
        * }
        */
        if (nextProps.clearFormFields) {
            this.setState(this.setInitialState(nextProps.clearFormFields));
        } else {
            for (let i = 0; i < fields.length; i++) {
                let fieldId = fields[i].id;
                let fieldType = fields[i].type;
    
                if (fieldType === 'checkbox') {
                    state[fieldId] = fields[i].checked;
                } else {
                    state[fieldId] = fields[i].value || this.state[fieldId];
                }
            }
            this.setState(state);
        }
    }

    setInitialState(clearFormFields) {
        let initialState = {};

        this.props.fields.forEach(field => {
            if (field.type === 'fieldGroup') {
                field.children.forEach(field => {
                    const { id } = field;
                    let defaultValue;
                    
                    if (field.type === 'checkbox') {
                        defaultValue = field.checked;
                    } else {
                        defaultValue = clearFormFields ? '' : field.value || '';
                    }

                    initialState[id] = this.props.initialState && this.props.initialState[id] ? this.props.initialState[id] : defaultValue
                })
            } else {
                const { id } = field;
                let defaultValue;

                if (field.type === 'checkbox') {
                    defaultValue = field.checked || false;
                } else {
                    defaultValue = clearFormFields ? '' : field.value || '';
                }
                
                initialState[id] = 
                    this.props.initialState && this.props.initialState[id] 
                        ? this.props.initialState[id] 
                        : defaultValue
            }
        });
        
        return initialState;
    }

    renderFormContent() {
        if (!this.props.fields) return null;

        let fields = [];

        this.props.fields.forEach((field, i) => {
            const { type } = field;

            if (type === 'fieldGroup') {
                fields.push(
                    <div key={`group-${i}`} className="form__field-group">
                        {field.children.map(field => this.renderField(field))}
                    </div>
                )
            } else {
                fields.push(this.renderField(field))
            }
        });
        
        return fields;
    }

    renderField(field) {
        const { type, id, label, placeholder, maxlength, required, modifier, disabled } = field;
        
        return (
            <div key={field.id} className="form__field">
                <label htmlFor={id} className={`form__label form__label--${type}`}>{label}</label>
                
                {type === 'textarea' ? (
                    <textarea
                        className={`form__input form__input--${modifier}`}
                        name={id}
                        id={id}
                        value={this.state[id]}
                        onChange={e => this.setState({ [id]: e.target.value })}
                        required={required}
                        maxLength={maxlength ? maxlength : 300}
                        placeholder={placeholder}
                    ></textarea>
                ) : (
                    <>
                        <input
                            className={`form__input form__input--${modifier}`}
                            type={type}
                            id={id}
                            name={id}
                            value={this.state[id] || ''}
                            onChange={e => 
                                this.setState({ [id]: type === 'checkbox' ? e.target.checked : e.target.value })
                            }
                            required={required}
                            placeholder={placeholder}
                            checked={ type === 'checkbox' && this.state[id] ? this.state[id] : false }
                            disabled={disabled}
                        />
                        {type === 'checkbox' &&
                            <span className="form__input-checkbox"></span>
                        }
                    </>
                )}
            </div>
        )
    }

    render() {
        const { submit, error, success, modifier } = this.props;
        let { message } = this.props;
        const classNames = cx('form', modifier && 'form--' + modifier, error && 'form--error', success && 'form--success');
        const graphQlErrorString = 'GraphQL error:';

        /* Required until Apollo Client gives option to remove hardcoded part of GraphQL errors */
        if (message && message.indexOf(graphQlErrorString) === 0) {
            message = message.substr(graphQlErrorString.length+1);
        }

        return (
            <form className={classNames} onSubmit={(e) => this.props.submit.handler(e, this.state)}>
                {this.renderFormContent()}

                <div className={cx('form__footer', submit.modifier && `form__footer--${submit.modifier}`)}>
                    <button type="submit" className={cx('form__submit button', submit.modifier && `button--${submit.modifier}`)}>
                        <span className="button__inner">{submit.label}</span>
                    </button>

                    <div className={`form__status`}>
                        {message}
                    </div>
                </div>
            </form>
        )
    }
}

export default Form;
