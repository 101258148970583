import React from "react";
import "./result-graph.scss";

import { GoalQuickview, GoalHero } from "../../layouts";
import { BoxRow, Modal } from "../../components";

import { impactColors } from "../../constants";
import { AppContext } from "../../context/AppContext";

const ResultGraph = React.memo((props) => {
	const { getContentByDocType } = React.useContext(AppContext);
	const { page, goals } = getContentByDocType("goal_page");
	const scroller = React.useRef(null);
	const [itemsWidth, setItemsWidth] = React.useState(null);
	const [scrollFadeModifiers, setScrollFadeModifiers] = React.useState({
		left: "",
		right: "",
	});

	const goalModalInitialState = {
		open: false,
		goal_id: 1,
	};
	const [goalModal, setGoalModal] = React.useState(goalModalInitialState);
	const delayedResetGoalModal = () =>
		setTimeout(() => setGoalModal(goalModalInitialState), 500);

	const getItemsWidth = () => {
		const rows = scroller.current?.children ?? null;

		if (rows.length > 0) {
			const rowItems = rows[0].children;
			let width = 0;

			for (let i = 0; i < rowItems.length; i++) {
				const itemStyles = window.getComputedStyle(rowItems[i]);

				let itemWidth = rowItems[i].offsetWidth;
				itemWidth += parseFloat(itemStyles.marginLeft);
				itemWidth += parseFloat(itemStyles.marginRight);

				width += itemWidth;
			}

			setItemsWidth(width);
		}
	};

	const setScrollFade = () => {
		// Prevent bug where window resize triggers modal
		setGoalModal(goalModalInitialState);

		const scrollerEl = scroller.current;
		let scrollFadeModifiers = { left: "", right: "" };

		if (scrollerEl.scrollLeft > 0) {
			scrollFadeModifiers.left = "result-grap--scroll-fade-left";
		}

		let rightOverflow =
			itemsWidth - scrollerEl.offsetWidth - scrollerEl.scrollLeft;

		if (itemsWidth > scrollerEl.offsetWidth && !!rightOverflow) {
			scrollFadeModifiers.right = "result-grap--scroll-fade-right";
		}

		setScrollFadeModifiers(scrollFadeModifiers);
	};

	React.useEffect(() => {
		getItemsWidth();
	}, []);

	React.useEffect(() => {
		window.addEventListener("resize", setScrollFade, false);

		return () => window.removeEventListener("resize", setScrollFade, false);
	}, []);

	// Combine impact options with their respective colors
	const impactLevels =
		page.impact_options?.reduce((acc, option) => {
			return [
				...acc,
				{
					...option,
					color: impactColors.find(
						(color) => color.impact === option.value
					).color,
				},
			];
		}, []) ?? null;

	return (
		<div
			className={`result-graph ${scrollFadeModifiers.left} ${scrollFadeModifiers.right}`}
		>
			<div className="result-graph__inner">
				<div
					ref={scroller}
					className="result-graph__scrollable"
					onScroll={setScrollFade}
				>
					{impactLevels &&
						impactLevels.map((level, i) => {
							const { value, color, label } = level;

							return (
								<BoxRow
									key={i}
									id={value}
									label={label}
									color={color}
									items={props.goals}
									type={value == 0 ? "standalone" : ""}
									clickHandler={(e, goal_id) => {
										e.preventDefault();
										setGoalModal({ open: true, goal_id });
									}}
								/>
							);
						})}
				</div>
			</div>

			{goals && (
				<Modal
					show={goalModal.open}
					onClose={() => delayedResetGoalModal()}
					contentModifier={"full-width"}
					size={`small`}
				>
					<GoalHero
						{...goals[goalModal.goal_id - 1]}
						goalContent={goals[goalModal.goal_id - 1]}
						goal_id={goalModal.goal_id}
						size="small"
					/>
					<GoalQuickview
						goal={props.goals[goalModal.goal_id - 1]}
						goalContent={goals[goalModal.goal_id - 1]}
					/>
				</Modal>
			)}
		</div>
	);
});

export default ResultGraph;
