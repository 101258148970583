import React from "react";
import { Layout, Footer } from "../../components";
import { AppContext } from "../../context/AppContext";
import { RichText } from "prismic-reactjs";
import { linkResolver } from "../../utils/prismic";

const NotFoundPage = () => {
	const { getContentByDocType } = React.useContext(AppContext);
	const { page } = getContentByDocType("404_page");

	return (
		page && (
			<>
				<Layout
					title={page.title}
					width="width-8"
					backgroundColor="white"
				>
					{RichText.render(page.body, linkResolver)}
				</Layout>
				<Footer />
			</>
		)
	);
};

export default NotFoundPage;
