import React from "react";
import { Option, GoalCard } from "../../components";
import "./goal-print-box.scss";

import { AppContext } from "../../context/AppContext";

const GoalPrintBox = (props) => {
	const { getContentByDocType } = React.useContext(AppContext);
	const { goals, page } = getContentByDocType("goal_page");
	const { goal_id, motivation, impact } = props;
	const { title, description } = goals && goals[goal_id - 1];

	const titles = title.split("--");
	let formattedTitle = "";
	let impactLabel = "";
	titles.forEach((title) => (formattedTitle += title));

	page.impact_options.forEach((option) => {
		if (impact === option.value) {
			impactLabel = option.label;
		} else if (impact === 0) {
			impactLabel = page.impact_options[5].label;
		}
	});

	const goal = goals.find((g) => g.id === goal_id);

	return (
		<div className="goal-print-box">
			<div className="goal-print-box__header">
				<div
					className={`goal-print-box__goal-id goal-print-box__goal-id--${goal_id}`}
				>
					<GoalCard goal_id={goal_id} logoUrl={goal.logo?.url} />
				</div>
				<div className="goal-print-box__header-content">
					<h2 className="goal-print-box__title">{formattedTitle}</h2>
					<p className="goal-print-box__description">{description}</p>
				</div>
			</div>

			<div className="goal-print-box__content">
				<div className="goal-print-box__content-box">
					<h3 className="goal-print-box__content-title">Impact</h3>
					<Option
						label={impactLabel}
						impactRating={impact}
						type={`locked`}
					/>
				</div>
				<div className="goal-print-box__content-box">
					<h3 className="goal-print-box__content-title">
						Motivation
					</h3>
					<p className="goal-print-box__content-text">{motivation}</p>
				</div>
			</div>
		</div>
	);
};

GoalPrintBox.defaultProps = {
	goal_id: "",
	title: "",
	description: "",
};

export default GoalPrintBox;
