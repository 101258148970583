import React from "react";
import cx from "classnames";
import "./modal.scss";

const ESCKEY = 27;

const Modal = (props) => {
	const {
		title,
		description,
		size,
		contentModifier,
		preventClose,
		position,
	} = props;
	const [open, setOpen] = React.useState(props.show || false);
	const ref = React.useRef(null);
	const classNames = cx("modal", size && "modal--" + size);

	const closeModal = () => {
		props.onClose && props.onClose();

		setOpen(false);
	};

	const handleKeyUp = (e) => {
		if (e.which === ESCKEY) {
			e.preventDefault();
			closeModal();
		}
	};

	const handleClickOutside = (e) => {
		const target = e.target;

		if (target === ref?.current?.children[0]) {
			props.onClickOutside && props.onClickOutside();
			closeModal();
		}
	};

	const addEventListeners = () => {
		document.addEventListener("keyup", handleKeyUp);
		document.addEventListener("click", handleClickOutside);
	};

	const removeEventListeners = () => {
		document.removeEventListener("keyup", handleKeyUp);
		document.removeEventListener("click", handleClickOutside);
	};

	React.useEffect(() => {
		if (open) {
			addEventListeners();
		} else removeEventListeners();
	}, [open]);

	React.useEffect(() => {
		setOpen(props.show);
		addEventListeners();
	}, [props.show]);

	React.useEffect(() => {
		removeEventListeners();
	}, [props.preventClose]);

	// Clean up event listeners
	React.useEffect(() => {
		return () => removeEventListeners();
	});

	return (
		<div ref={ref} className={classNames} aria-hidden={!open}>
			<div className="modal__inner">
				<div className="modal__box">
					{preventClose === undefined && (
						<button
							className="modal__close-btn"
							onClick={() => closeModal()}
						>
							<svg className="icon">
								<use xlinkHref="#icon-close" />
							</svg>
						</button>
					)}

					<div
						className={cx(
							"modal__content",
							contentModifier &&
								"modal__content--" + contentModifier,
							position && "modal__content--" + position
						)}
					>
						{title && description && (
							<div className="modal__content-header">
								<h2 className="modal__title">{title}</h2>
								{description && (
									<div className="rte">{description}</div>
								)}
							</div>
						)}

						{props.children}
					</div>
				</div>
			</div>
		</div>
	);
};

export default Modal;
