export const impactColors = [
	{
		impact: 5,
		color: "green",
	},
	{
		impact: 4,
		color: "green",
	},
	{
		impact: 3,
		color: "gray",
	},
	{
		impact: 2,
		color: "red",
	},
	{
		impact: 1,
		color: "red",
	},
	{
		impact: 0,
		color: "gray-light",
	},
];
