import "whatwg-fetch";
import React from "react";
import ReactDOM from "react-dom";
import { Login } from "./pages";

import { AppContextProvider } from "./context";
const loginRoot = document.getElementById("login");

if (loginRoot) {
	ReactDOM.render(
		<AppContextProvider>
			<Login />
		</AppContextProvider>,
		loginRoot
	);
}
