import React from "react";
import "./footer.scss";

import { AppContext } from "../../context/AppContext";

const Footer = (props) => {
	const { getContentByDocType } = React.useContext(AppContext);

	const { footer } = getContentByDocType();

	if (!footer) return <div></div>;
	const { logo_gmv, logo_chalmers, logo_gu, logo_sdsn } = footer;

	return (
		<div className="footer">
			<div className="footer__inner">
				<div className="footer__row">
					<div
						className="footer__logo footer__logo--gmv"
						style={{ backgroundImage: `url(${logo_gmv.url})` }}
					></div>
					<div
						className="footer__logo footer__logo--sdsn"
						style={{ backgroundImage: `url(${logo_sdsn.url})` }}
					></div>
				</div>
				<div className="footer__row">
					<div
						className="footer__logo footer__logo--chal"
						style={{ backgroundImage: `url(${logo_chalmers.url})` }}
					></div>
					<div
						className="footer__logo footer__logo--gu"
						style={{ backgroundImage: `url(${logo_gu.url})` }}
					></div>
				</div>
			</div>
		</div>
	);
};

export default Footer;
