import React from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";

import { SINGLE_ASSESSMENT } from "../../graphql/queries";
import GoalRelevanceBar from "./GoalRelevanceBar";
import { LoadingIndicator, ErrorMessage } from "../../components";
import { GoalHero } from "../../layouts";
import { NotFoundPage } from "../../pages";
import { AppContext } from "../../context/AppContext";

const AssessmentSortingPage = (props) => {
	const { appData, getContentByDocType } = React.useContext(AppContext);
	const { goals, common_translations } = getContentByDocType();
	const { user_id } = appData.user;
	const { url_alias, goal_id } = useParams();
	const { loading, error, data } = useQuery(SINGLE_ASSESSMENT, {
		variables: { user_id, url_alias },
	});

	if (!goals) return <NotFoundPage />;
	if (loading) return <LoadingIndicator />;

	const errors = common_translations.error_messages[0] ?? null;

	if (error)
		return (
			<ErrorMessage error={errors?.general || "Something went wrong"} />
		);

	const goalContent = goals.find((goal) => goal.id === parseInt(goal_id));

	const assessment = data.getAssessment;

	return (
		<>
			<GoalHero size="large" goalContent={goalContent} />
			<GoalRelevanceBar assessment={assessment} />
		</>
	);
};

export default AssessmentSortingPage;
