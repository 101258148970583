import React from "react";
import cx from "classnames";
import "./box-row.scss";

import { Box } from "../../components";

const BoxRow = (props) => {
	const { id, items, label, color, type } = props;

	const classNames = cx(
		"box-row",
		color && "box-row--" + color,
		type && "box-row--" + type
	);

	return (
		<div className={classNames}>
			<div className="box-row__label">
				<span>{label}</span>
			</div>

			{items.map((item, i) => {
				if (item.impact === id) {
					return (
						<Box
							goal={items[i]}
							key={i}
							clickHandler={(e, goal_id) =>
								props.clickHandler(e, goal_id)
							}
						/>
					);
				} else {
					return <Box key={i} />;
				}
			})}
		</div>
	);
};

export default BoxRow;
