import React from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { RichText } from "prismic-reactjs";

import { ASSESSMENT_GOAL } from "../../graphql/queries";
import {
	LoadingIndicator,
	ErrorMessage,
	Grid,
	TargetBar,
	Section,
} from "../../components";
import { GoalHero } from "../../layouts";
import { NotFoundPage } from "../../pages";
import GoalImpact from "./GoalImpact";

import { AppContext } from "../../context/AppContext";

const GoalPage = (props) => {
	const { getContentByDocType } = React.useContext(AppContext);
	const { _id, goal_id } = useParams();

	const { goals, common_translations } = getContentByDocType("goal_page");

	const { loading, error, data } = useQuery(ASSESSMENT_GOAL, {
		variables: { _id },
	});

	if (!goals) return <NotFoundPage />;
	if (loading) return <LoadingIndicator />;
	if (error)
		return (
			<ErrorMessage
				error={
					common_translations?.error_messages ??
					"Something went wrong"
				}
			/>
		);

	const goal = data.getAssessmentGoal;

	// Extract content for current goal
	const goalContent = goals.find((goal) => goal.id === parseInt(goal_id));
	if (!goalContent) {
		return <div></div>;
	}

	const { intro_title, intro_body, targets, targets_preamble } = goalContent;

	// Render each target
	const targetComponents = targets.map((target) => {
		const imageUrl = `/images/goals-targets/goal-${goalContent.id}/GOAL_${goalContent.id}_TARGETS/GOAL_${goalContent.id}_TARGETS_SVG/GOAL_${goalContent.id}_TARGET_${target.target_id}.svg`;
		return (
			<TargetBar
				title={target.target_title}
				description={target.target_description}
				imageUrl={imageUrl}
			/>
		);
	});

	return (
		<>
			{goalContent && <GoalHero goalContent={goalContent} />}
			<div className="page__content">
				<div className="goal">
					<Section title={intro_title} description={intro_body} />

					<div className="section section--white">
						<h2 className={`section__title`}>Targets</h2>
						<div className="section__preamble">
							{targets_preamble &&
								RichText.render(targets_preamble)}
						</div>

						<Grid cols={2} items={targetComponents} />
					</div>

					{goal && <GoalImpact goal={goal} />}
				</div>
			</div>
		</>
	);
};

export default GoalPage;
