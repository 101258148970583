import React from "react";
import { Button } from "../../components";
import "./assessment-summary.scss";

const AssessmentSummary = (props) => {
	const { description, summary, goals, buttonLabel, buttonHref } = props;
	const totalGoals = goals.length;
	let savedGoals = 0;

	for (let i = 0; i < goals.length; i++) {
		if (goals[i].saved) savedGoals++;
	}
	const resultButtonDisabled = savedGoals === totalGoals ? false : true;

	return (
		<div className={`assessment-summary`}>
			<div className={`assessment-summary__text`}>
				<div className={`assessment-summary__summary`}>
					<span>{savedGoals}</span>/
					<span>
						{totalGoals} {summary}
					</span>
				</div>

				<p className={`assessment-summary__body`}>{description}</p>
			</div>

			<div className={`assessment-summary__button-mobile`}>
				<Button
					link={buttonHref}
					label={buttonLabel}
					color={`black`}
					disabled={resultButtonDisabled}
					size={"small"}
				/>
			</div>

			<div className={`assessment-summary__button`}>
				<Button
					link={buttonHref}
					label={buttonLabel}
					color={`black`}
					disabled={resultButtonDisabled}
				/>
			</div>
		</div>
	);
};

export default AssessmentSummary;
