import React, { Component } from "react";
import "./lined-header.scss";

const LinedHeader = (props) => {
	const { headertitle } = props;

	return (
		<div className="lined-header">
			<h2 className="lined-header__title">{headertitle}</h2>
		</div>
	);
};

export default LinedHeader;
