import React from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";

import { SINGLE_ASSESSMENT } from "../../graphql/queries";
import { LoadingIndicator, ErrorMessage } from "../../components";
import { AssessmentGoals } from "../../layouts";
import { NotFoundPage } from "../../pages";
import { AppContext } from "../../context/AppContext";

const AssessmentGoalsPage = (props) => {
	const { appData, getContentByDocType } = React.useContext(AppContext);
	const { common_translations, goals } = getContentByDocType();
	const { user_id } = appData.user;
	const { url_alias } = useParams();
	const errors = common_translations?.error_messages[0] ?? null;
	const { loading, error, data } = useQuery(SINGLE_ASSESSMENT, {
		variables: { user_id, url_alias },
	});

	if (!goals) return <NotFoundPage />;
	if (loading) return <LoadingIndicator />;
	if (error) return <ErrorMessage error={errors.general} />;

	const assessment = data.getAssessment;

	return (
		<div className="page__content">
			<AssessmentGoals assessment={assessment} />
		</div>
	);
};

export default AssessmentGoalsPage;
