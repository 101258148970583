import React from "react";
import "./goal-quickview.scss";

import { TargetBar, Option, Accordion } from "../../components";
import { AppContext } from "../../context/AppContext";

const GoalQuickview = React.memo((props) => {
	const { getContentByDocType } = React.useContext(AppContext);
	const { impact, motivation } = props.goal;
	const { targets, id } = props.goalContent;
	const { page } = getContentByDocType("goal_page");
	const { page: resultPage } = getContentByDocType("result_page");

	const targetComponents = targets.map((target) => {
		const imageUrl = `/images/goals-targets/goal-${id}/GOAL_${id}_TARGETS/GOAL_${id}_TARGETS_SVG/GOAL_${id}_TARGET_${target.target_id}.svg`;
		return (
			<TargetBar
				key={target.target_id}
				title={target.target_title}
				description={target.target_description}
				imageUrl={imageUrl}
				size={`small`}
			/>
		);
	});

	return (
		<div className="goal-quickview">
			<div className="section">
				<Accordion
					openLabel={`${
						resultPage?.goal_accordion_labels[0]?.open ??
						"Open targets"
					}`}
					closeLabel={`${
						resultPage?.goal_accordion_labels[0]?.close ??
						"Close targets"
					}`}
					id={id}
				>
					{targetComponents}
				</Accordion>
			</div>

			{page && (
				<div className="section">
					{impact != 0 ? (
						<Option
							type={`locked`}
							size={`small`}
							label={
								page.impact_options.find(
									(impactOpt) => impactOpt.value === impact
								).label
							}
						/>
					) : (
						<Option
							type={`locked`}
							size={`small`}
							label={page.impact_options[5].label}
						/>
					)}
				</div>
			)}

			<div className="section">
				<h2 className="section__title section__title--lowercase">
					{resultPage.motivation_title}
				</h2>
				<p className="section__description">{motivation}</p>
			</div>
		</div>
	);
});

export default GoalQuickview;
