import React from "react";
import { withRouter } from "react-router-dom";
import {
	Button,
	LanguageSwitch,
	Dropdown,
	Avatar,
	LoadingIndicator,
} from "../../components";
import "./header.scss";

import { AppContext } from "../../context/AppContext";

const Header = (props) => {
	const {
		locale,
		appData,
		headerDropdownOpen,
		openAssessmentModal,
		setHeaderDropdownOpen,
		setCreateAssessmentModalOpen,
		getContentByDocType,
	} = React.useContext(AppContext);

	const openHeaderDropdown = () => {
		setHeaderDropdownOpen(!headerDropdownOpen);
		setCreateAssessmentModalOpen(false);
	};

	const { header } = getContentByDocType();

	if (!header) return <LoadingIndicator />;

	return (
		<header className="header">
			<div className="header__inner">
				<a href={`/${locale}`} className="header__logo"></a>

				<ul className="header__nav">
					{header.internal_links &&
						header.internal_links.map((internalLink) => {
							const { link, label } = internalLink;
							return (
								<li
									key={link.id}
									className="header__nav-item header__nav-item--hidden-s"
								>
									<Button
										label={label}
										href={`/${locale}/articles/${link.uid}`}
										color={`white`}
										size={`small`}
									/>
								</li>
							);
						})}

					{header.show_language_select && (
						<li className="header__nav-item">
							<LanguageSwitch label={header.choose_language} />
						</li>
					)}

					{location.pathname == `/${locale}/tool/assessments` && (
						<li className="header__nav-item">
							<Button
								label={header.new_assessment}
								size={`small`}
								onClick={() => openAssessmentModal()}
							/>
						</li>
					)}

					{appData.user.user_id != "" && (
						<li className="header__nav-item">
							<Avatar
								initials={appData.user.name
									.substring(0, 1)
									.toUpperCase()}
								onClick={() => openHeaderDropdown()}
							/>
							<Dropdown show={headerDropdownOpen} key={1}>
								<Button
									href={`/auth/logout`}
									label={header.log_out}
									size={`small`}
								/>
							</Dropdown>
						</li>
					)}

					{appData.user.user_id == "" && (
						<li className="header__nav-item">
							<Button
								label={header.log_in}
								href={`/auth/login?lang=${locale}`}
								color={`black`}
								size={`small`}
							/>
						</li>
					)}
				</ul>
			</div>
		</header>
	);
};

export default withRouter(Header);
