import React from "react";
import cx from "classnames";

const Layout = (props) => {
	const {
		children,
		Hero,
		Header,
		title,
		preamble,
		backgroundColor,
		width,
	} = props;

	return (
		<div
			className={cx(
				`page`,
				backgroundColor && `page--${backgroundColor}`
			)}
		>
			{Header && <div className={`page__header`}>{Header}</div>}
			{Hero && Hero}
			<div
				className={cx(
					`page__content`,
					width && `page__content--` + width
				)}
			>
				{title && <h1 className={`page__title`}>{title}</h1>}
				{preamble && <p className={`page__preamble`}>{preamble}</p>}
				{children && children}
			</div>
		</div>
	);
};

export default Layout;
