import { gql } from "@apollo/client";

export const ASSESSMENTS = gql`
	query ($user_id: ID!) {
		getUser(user_id: $user_id) {
			assessments {
				_id
				user_id
				title
				description
				url_alias
				goals {
					goal_id
					saved
				}
			}
		}
	}
`;

export const SINGLE_ASSESSMENT = gql`
	query ($user_id: ID!, $url_alias: String!) {
		getAssessment(user_id: $user_id, url_alias: $url_alias) {
			_id
			title
			description
			url_alias
			goals {
				_id
				goal_id
				relevance
				impact
				motivation
				saved
			}
			strategy {
				questions {
					id
					checked
				}
				strategy
			}
		}
	}
`;

export const ASSESSMENT_GOAL = gql`
	query ($_id: ID!) {
		getAssessmentGoal(_id: $_id) {
			_id
			goal_id
			relevance
			impact
			motivation
			saved
		}
	}
`;
