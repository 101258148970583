import React from "react";
import { Link } from "react-router-dom";
import { useMutation } from "@apollo/client";

import { REMOVE_ASSESSMENT } from "../../graphql/mutations";
import { ASSESSMENTS } from "../../graphql/queries";
import { AppContext } from "../../context/AppContext";

import "./assessmentbar.scss";

const AssessmentBar = (props) => {
	const { _id, title, description, url_alias, goals } = props.assessment;
	const [overlayHidden, setOverlayHidden] = React.useState(true);
	const { openAssessmentModal, appData, translations, getContentByDocType } =
		React.useContext(AppContext);
	const { common_translations } = getContentByDocType();
	const labels = common_translations?.button_labels[0] ?? null;

	const url = url_alias ? `assessment/${url_alias}` : "";
	let savedGoals = 0;

	for (let i = 0; i < goals.length; i++) {
		if (goals[i].saved) savedGoals++;
	}

	const [removeAssessmentMutation] = useMutation(REMOVE_ASSESSMENT);

	const editAssesment = (e) => {
		e.preventDefault();
		const assessment = {
			_id: props.assessment._id,
			title: props.assessment.title,
			description: props.assessment.description,
		};

		openAssessmentModal(assessment, true);
	};

	const removeAssessment = async (e, assessmentId) => {
		e.preventDefault();
		const { user_id } = appData.user;

		try {
			await removeAssessmentMutation({
				variables: {
					_id: assessmentId,
				},
				refetchQueries: [
					{ query: ASSESSMENTS, variables: { user_id } },
				],
			});

			setOverlayHidden(true);
		} catch (error) {
			console.error(error);
		}
	};

	return (
		<Link className={`assessment-bar`} to={url}>
			<h3 className={`assessment-bar__title`}>{title}</h3>
			<p className={`assessment-bar__body`}>{description}</p>

			<div className={`assessment-bar__goals-counter`}>
				<span>{savedGoals}</span>/<span>17</span>
			</div>

			<button
				onClick={(e) => editAssesment(e)}
				className="assessmentbar__edit-button"
			/>
			<button
				onClick={(e) => {
					e.preventDefault();
					setOverlayHidden(false);
				}}
				className="assessmentbar__trash-button"
			/>

			<div
				className={`assessment-bar__overlay`}
				aria-hidden={overlayHidden}
				onClick={(e) => e.preventDefault()}
			>
				<div className="assessment-bar__overlay-btn-group">
					<button
						className="assessment-bar__overlay-btn"
						onClick={(e) => removeAssessment(e, _id)}
					>
						{labels?.yes__delete ?? "Yes, delete"}
					</button>
					<button
						className="assessment-bar__overlay-btn"
						onClick={() => setOverlayHidden(true)}
					>
						{labels?.cancel ?? "Cancel"}
					</button>
				</div>
			</div>
		</Link>
	);
};

export default AssessmentBar;
