import React from "react";
import { useQuery } from "@apollo/client";

import { ASSESSMENTS } from "../../graphql/queries";
import { LoadingIndicator, ErrorMessage } from "../../components";
import { Assessments } from "../../layouts";
import { NotFoundPage } from "../../pages";
import CreateAssessmentForm from "./CreateAssessmentForm.jsx";
import { AppContext } from "../../context/AppContext";

const AssessmentsPage = (props) => {
	const { appData, getContentByDocType } = React.useContext(AppContext);
	const { user_id } = appData.user;
	const { page, common_translations } =
		getContentByDocType("assessments_page");
	const errors = common_translations?.error_messages[0] ?? null;
	const { loading, error, data } = useQuery(ASSESSMENTS, {
		variables: { user_id },
	});

	if (!page) return <NotFoundPage />;
	if (loading) return <LoadingIndicator />;
	if (error) return <ErrorMessage error={errors.general} />;

	const user = data.getUser;

	return (
		<div className="page__content">
			<>
				<Assessments {...user} />
				{page && (
					<CreateAssessmentForm
						content={{
							create: page.create_assessment_modal[0],
							update: page.update_assessment_modal[0],
						}}
					/>
				)}
			</>
		</div>
	);
};

export default AssessmentsPage;
