import React, { Component } from "react";
import "./avatar.scss";

const Avatar = (props) => {
	const { initials, onClick } = props;

	return (
		<div className={`avatar`} onClick={onClick}>
			<span className={`avatar__initials`}>{initials}</span>
		</div>
	);
};

export default Avatar;
