import React from "react";

export const defaultLocale = "en-gb";

export const AppContext = React.createContext({
	allowedLocales: [],
	locale: defaultLocale,
	activeAssessment: null,
	appData: null,
	content: null,
	getContentByDocType: (docType) => {},
	createAssessmentModalOpen: false,
	headerDropdownOpen: false,
});

export const AppContextProvider = (props) => {
	const [allowedLocales, setAllowedLocales] = React.useState([]);
	const [locale, setLocale] = React.useState(defaultLocale);
	const [activeAssessment, setActiveAssessment] = React.useState(null);
	const [appData, setAppData] = React.useState(null);
	const [content, setContent] = React.useState(null);
	const [
		createAssessmentModalOpen,
		setCreateAssessmentModalOpen,
	] = React.useState(false);
	const [headerDropdownOpen, setHeaderDropdownOpen] = React.useState(false);

	React.useEffect(() => {
		const appDataParsed = JSON.parse(
			document.getElementById("appData").innerHTML
		);
		const content = JSON.parse(
			document.getElementById("content").innerHTML
		);

		const apiLocales = appDataParsed.allowedLocales.split(",");

		setAllowedLocales(apiLocales);
		setAppData(appDataParsed);
		setContent(content);
		setLocale(appDataParsed.locale);
	}, []);

	const getContentByDocType = (docType) => {
		return content.content.reduce((acc, doc) => {
			return {
				...acc,
				...((doc.uid === docType && { page: doc.data }) ||
					(doc.type === docType && { page: doc.data })),
				...(doc.type === "header" && { header: doc.data }),
				...(doc.type === "footer" && { footer: doc.data }),
				...(doc.type === "goals" && {
					goals: doc.data.body.reduce((acc, goal) => {
						return [
							...acc,
							{
								...goal.primary,
								targets: goal.items,
							},
						];
					}, []),
				}),
				...(doc.type === "common_translations" && {
					common_translations: doc.data,
				}),
			};
		}, {});
	};

	const openAssessmentModal = (assessment, updateAssessment = false) => {
		setActiveAssessment(updateAssessment && assessment ? assessment : null);
		setCreateAssessmentModalOpen(true);
		setHeaderDropdownOpen(false);
	};

	return (
		<AppContext.Provider
			value={{
				locale,
				setLocale,
				allowedLocales,
				activeAssessment,
				setActiveAssessment,
				openAssessmentModal,
				appData,
				content,
				getContentByDocType,
				createAssessmentModalOpen,
				setCreateAssessmentModalOpen,
				headerDropdownOpen,
				setHeaderDropdownOpen,
			}}
		>
			{content && props.children}
		</AppContext.Provider>
	);
};
