import React from "react";
import cx from "classnames";
import "./grid.scss";

const Grid = (props) => {
	const { items, cols } = props;
	const gridItems = items.map((item, idx) => {
		return (
			<div className={`grid__item`} key={idx}>
				{item}
			</div>
		);
	});

	return (
		<div className={cx(`grid`, cols && `grid--cols-${cols}`)}>
			{gridItems}
		</div>
	);
};

Grid.defaultProps = {
	items: [],
};

export default Grid;
