import React from "react";
import { useMutation } from "@apollo/client";

import { CREATE_ASSESSMENT, UPDATE_ASSESSMENT } from "../../graphql/mutations";
import { ASSESSMENTS } from "../../graphql/queries";

import { Modal, Form } from "../../components";
import { AppContext } from "../../context/AppContext";

const CreateAssessmentForm = (props) => {
	const {
		appData,
		activeAssessment,
		createAssessmentModalOpen,
		setCreateAssessmentModalOpen,
		getContentByDocType,
	} = React.useContext(AppContext);
	const { common_translations } = getContentByDocType();
	const errors = common_translations?.error_messages[0] ?? null;
	const [createAssessmentError, setCreateAssessmentError] = React.useState(
		""
	);
	const { create, update } = props.content;
	const {
		title_field_label,
		description_field_label,
		description_field_placeholder,
	} = create || {};

	// If we have assessment, show translations for updating, otherwise for creating assessment
	const { title, description } = activeAssessment ? update : create;

	const titleDefaultValue = activeAssessment ? activeAssessment.title : "";
	const descriptionDefaultValue = activeAssessment
		? activeAssessment.description
		: "";
	const submitLabel = activeAssessment
		? update.submit_button_label
		: create.submit_button_label;

	const [createAssessmentMutation] = useMutation(CREATE_ASSESSMENT);
	const [updateAssessmentMutation] = useMutation(UPDATE_ASSESSMENT);

	const createAssessment = async (e, formState) => {
		e.preventDefault();
		const { title, description } = formState;
		const { user_id } = appData.user;

		if (title && description) {
			try {
				await createAssessmentMutation({
					variables: {
						user_id,
						title,
						description,
					},
					refetchQueries: [
						{
							query: ASSESSMENTS,
							variables: { user_id },
						},
					],
				});

				setCreateAssessmentModalOpen(false);
				setCreateAssessmentError("");
			} catch (error) {
				setCreateAssessmentError(
					error?.message?.indexOf("unique") > -1
						? errors?.choose_a_unique_title
						: errors?.general ?? "Something went wrong"
				);
			}
		} else {
			setCreateAssessmentError(
				errors?.save_assessement ?? "Couldn't save assessment"
			);
		}
	};

	const updateAssessment = async (e, formState) => {
		e.preventDefault();
		const { title, description } = formState;
		const { user_id } = appData.user;
		const url_alias = title.replace(/ /g, "-").toLowerCase();

		if (title && description) {
			try {
				await updateAssessmentMutation({
					variables: {
						user_id,
						url_alias,
						title,
						description,
					},
					refetchQueries: [
						{
							query: ASSESSMENTS,
							variables: { user_id },
						},
					],
				});

				setCreateAssessmentModalOpen(false);
				setCreateAssessmentError("");
			} catch (error) {
				console.log(error);
			}
		}
	};

	return (
		<Modal
			show={createAssessmentModalOpen}
			title={title}
			description={description}
			onClickOutside={() => setCreateAssessmentModalOpen(false)}
			onClose={() => setCreateAssessmentModalOpen(false)}
		>
			<Form
				fields={[
					{
						type: "text",
						required: true,
						id: "title",
						label: title_field_label,
						value: titleDefaultValue,
						disabled: activeAssessment,
					},
					{
						type: "textarea",
						required: true,
						id: "description",
						label: description_field_label,
						placeholder: description_field_placeholder,
						maxlength: 4000,
						value: descriptionDefaultValue,
					},
				]}
				error={createAssessmentError !== "" ? true : false}
				message={createAssessmentError}
				clearFormFields={activeAssessment ? false : true}
				submit={{
					label: submitLabel,
					handler: activeAssessment
						? (e, formState) => updateAssessment(e, formState)
						: (e, formState) => createAssessment(e, formState),
				}}
			/>
		</Modal>
	);
};

export default CreateAssessmentForm;
