import { gql } from "@apollo/client";

export const CREATE_ASSESSMENT = gql`
	mutation($user_id: ID!, $title: String!, $description: String!) {
		createAssessment(
			user_id: $user_id
			title: $title
			description: $description
		) {
			title
			description
		}
	}
`;

export const UPDATE_ASSESSMENT = gql`
	mutation(
		$user_id: ID!
		$url_alias: String!
		$title: String!
		$description: String!
	) {
		updateAssessment(
			user_id: $user_id
			url_alias: $url_alias
			title: $title
			description: $description
		) {
			description
			title
			url_alias
		}
	}
`;

export const REMOVE_ASSESSMENT = gql`
	mutation($_id: ID!) {
		removeAssessment(_id: $_id) {
			_id
			user_id
			title
		}
	}
`;

export const UPDATE_GOAL = gql`
	mutation($_id: ID!, $input: GoalInput!) {
		updateGoal(_id: $_id, input: $input) {
			relevance
			impact
			motivation
			saved
		}
	}
`;

export const ADD_OR_UPDATE_STRATEGY = gql`
	mutation(
		$assessment_id: ID!
		$questions: [QuestionInput!]
		$strategy: String!
	) {
		addOrUpdateAssessmentStrategy(
			assessment_id: $assessment_id
			questions: $questions
			strategy: $strategy
		) {
			questions {
				id
				checked
			}
			strategy
		}
	}
`;
