import React from "react";
import "./language-switch.scss";

import { AppContext } from "../../context/AppContext";

const LanguageSwitch = (props) => {
	const { locale, setLocale, allowedLocales } = React.useContext(AppContext);

	const handleOnChange = (e) => {
		setLocale(e.target.value);
		window.location.href = `/${e.target.value}`;
	};

	return (
		<div className="language-switch">
			<label htmlFor="language-switch-select">{`${props.label}:`}</label>
			<select
				value={locale}
				onChange={handleOnChange}
				id="language-switch-select"
				className={"language-switch__select"}
			>
				{allowedLocales.map((allowedLocale) => {
					const label = allowedLocale.split("-")[0].toUpperCase();
					return (
						<option key={allowedLocale} value={allowedLocale}>
							{label}
						</option>
					);
				})}
			</select>
			<span className="language-switch__arrow">
				<svg viewBox="0 0 24 24">
					<polygon
						points="7.1,8.3 11.7,12.9 16.3,8.3 17.7,9.7 11.7,15.7 5.7,9.7"
						fill="#FFFFFF"
					></polygon>
				</svg>
			</span>
		</div>
	);
};

export default LanguageSwitch;
