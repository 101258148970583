import React from "react";
import { useParams, useLocation } from "react-router-dom";
import { Button } from "../../components";
import cx from "classnames";
import { AppContext } from "../../context/AppContext";

const GoalHero = (props) => {
	const { getContentByDocType } = React.useContext(AppContext);
	const { common_translations } = getContentByDocType("goal_page");
	const { goal_id } = props.goal_id ? props : useParams();
	const location = useLocation();

	let url = location.pathname.split("/");
	url = `/${url[1]}/${url[2]}/${url[3]}/${url[4]}`;

	const titles = props.goalContent.title.split("--").map((item, idx) => {
		const text = item.trim();
		return (
			<span className={`goalhero__title-line`} key={idx}>
				{text}
			</span>
		);
	});

	const classNames = cx(
		`goalhero`,
		`goalhero--${goal_id}`,
		props.size && `goalhero--` + props.size
	);

	return (
		<div className={classNames}>
			<div className={`goalhero__inner goalhero__inner--top`}>
				{common_translations && url && (
					<Button
						link={url}
						label={
							common_translations?.button_labels[0]
								?.back_to_assessment_board ?? "Back"
						}
						color={`black`}
						opacity={0.7}
					/>
				)}
			</div>
			<div className={`goalhero__inner`}>
				<div className={`goalhero__content`}>
					<span className={`goalhero__number`}>{goal_id}</span>
					<div className={`goalhero__text`}>
						<h1 className={`goalhero__title`}>{titles}</h1>
						<p className={`goalhero__description`}>
							{props.goalContent.description}
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default GoalHero;
