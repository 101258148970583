import React from "react";

import { SearchBar, AssessmentBar } from "../../components";
import { NotFoundPage } from "../../pages";
import { AppContext } from "../../context/AppContext";

const Assessments = (props) => {
	const { getContentByDocType } = React.useContext(AppContext);
	const [searchTerm, setSearchTerm] = React.useState("");
	const [visibleAssessments, setVisibleAssessments] = React.useState(
		props.assessments
	);
	const { page } = getContentByDocType("assessments_page");

	if (!page) return <NotFoundPage />;

	const searchAssessments = (input) => {
		let items = [];

		items = props.assessments.filter((item) => {
			return item.title.toLowerCase().search(input) !== -1;
		});

		setSearchTerm(input);
		setVisibleAssessments(items);
	};

	const searchBar = {
		placeholder: page.search_placeholder,
		value: searchTerm,
		onChange: (input) => {
			searchAssessments(input);
		},
	};

	React.useEffect(() => {
		setVisibleAssessments(props.assessments);
	}, [props.assessments]);

	return (
		<>
			{props.assessments.length > 0 && <SearchBar {...searchBar} />}

			<ul className="assessment-list">
				{props.assessments.length > 0 ? (
					visibleAssessments.map((assessment, i) => {
						return (
							<AssessmentBar key={i} assessment={assessment} />
						);
					})
				) : (
					<p style={{ textAlign: "center" }}>
						{page.no_assessments_message}
					</p>
				)}
			</ul>
		</>
	);
};

export default Assessments;
