import React from "react";

const List = (props) => {
	const renderListItems = () => {
		const { items } = props;

		return items.map((item, i) => (
			<li key={i} className="list__item">
				{item.checkbox ? (
					<>
						<label
							htmlFor={`cb-${item.id}`}
							className="list__item-label"
						>
							{item.label}
							<input
								type="checkbox"
								id={`cb-${item.id}`}
								value={item.id}
							/>
							<span className="list__item-checkbox"></span>
						</label>
					</>
				) : (
					item.label
				)}
			</li>
		));
	};

	return (
		<div>
			{props.type === "ol" ? (
				<ol className="list">{renderListItems()}</ol>
			) : (
				<ul className="list">{renderListItems()}</ul>
			)}
		</div>
	);
};

List.defaultProps = {
	type: "ol",
	items: [],
};

export default List;
