import React from "react";
import { Link } from "react-router-dom";
import cx from "classnames";

import "./goal-card.scss";

const GoalCard = (props) => {
	const { goal_id, title, href, saved, logoUrl } = props;
	const classNames = cx(
		"goal-card",
		goal_id && `goal-card--goal-` + goal_id,
		saved && `goal-card--saved`
	);

	return (
		<Link to={href} className={classNames}>
			<div
				className="goal-card__figure"
				style={logoUrl && { backgroundImage: `url(${logoUrl})` }}
			></div>
			<h3 className={`goal-card__title`}>{title}</h3>

			<div className="goal-card__status">
				<div className="goal-card__status-done">
					<svg className="icon">
						<use xlinkHref="#icon-check" />
					</svg>
				</div>
				{props.locales && (
					<>
						<span className="goal-card__status-done__text">
							{props.locales.done}
						</span>

						<span className="goal-card__status-edit">
							{props.locales.edit}
						</span>
					</>
				)}
			</div>
		</Link>
	);
};

GoalCard.defaultProps = {
	goal_id: "",
	title: "",
	href: "",
};

export default GoalCard;
