import React from "react";
import objectFitImages from "object-fit-images";
import { RichText } from "prismic-reactjs";
import { Button, Footer } from "../../components";
import { NotFoundPage } from "../../pages";
import "./hero.scss";

import { AppContext } from "../../context/AppContext";

const Hero = ({ buttonUrl }) => {
	const { getContentByDocType } = React.useContext(AppContext);
	const image = React.useRef(null);

	const { page } = getContentByDocType("startpage");

	React.useEffect(() => {
		objectFitImages(image);
	}, []);

	if (!page) return <NotFoundPage />;

	return (
		<>
			<div className={`hero`}>
				<img
					src={page.hero_image.url}
					alt={page.hero_image.alt}
					className={`hero__image`}
					ref={image}
				/>
				<div className={`hero__content`}>
					<h2 className={`hero__title`}>
						{RichText.asText(page.title)}
					</h2>
					<div className={`hero__button`}>
						<Button
							label={page.button_label}
							color={`white`}
							href={buttonUrl}
						/>
					</div>
				</div>
			</div>
			<Footer />
		</>
	);
};

export default Hero;
