import React from "react";
import cx from "classnames";
import "./box.scss";

const Box = (props) => {
	const { goal } = props;
	const { goal_id } = goal ? goal : {};
	const classNames = cx("box", goal && "box--with-content");
	let clickHandler;

	if (!props.clickHandler) {
		clickHandler = () => {
			return false;
		};
	} else {
		clickHandler = props.clickHandler;
	}

	return (
		<div className={classNames} onClick={(e) => clickHandler(e, goal_id)}>
			<div className={`box__content box__content--goal-${goal_id}`}>
				<span>{goal_id && goal_id}</span>
			</div>
		</div>
	);
};

export default Box;
