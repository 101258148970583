import React from "react";
import cx from "classnames";
import "./option.scss";

const Option = (props) => {
	const { label, value, clickHandler, selected, type, size } = props;
	const classNames = cx(
		"option",
		type && "option--" + type,
		selected && "option--selected",
		size && "option--" + size
	);

	return (
		<button
			className={`${classNames}`}
			data-value={value}
			onClick={clickHandler}
		>
			{label}

			<div className={`option__checkbox`}>
				<svg className="icon">
					<use xlinkHref="#icon-check" />
				</svg>
			</div>
		</button>
	);
};

export default Option;
