import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import { Header, Hero } from "../../components";
import { StandardPage, NotFoundPage } from "..";
import { AppContext } from "../../context/AppContext";

const Login = (props) => {
	const { locale } = React.useContext(AppContext);

	return (
		<div className="page">
			<Router>
				<>
					<Route
						path={`/:lang`}
						render={() => (
							<div className={`page__header`}>
								<Header />
							</div>
						)}
					/>
					<Switch>
						<Route
							exact
							path="/:lang"
							render={(props) => (
								<Hero
									buttonUrl={`/auth/login?lang=${locale}`}
								/>
							)}
						/>

						<Route
							exact
							path="/:lang/articles/:page_uid"
							render={(props) => <StandardPage {...props} />}
						/>

						{/* 404 page */}
						<Route path="*" render={() => <NotFoundPage />} />
					</Switch>
				</>
			</Router>
		</div>
	);
};

export default Login;
