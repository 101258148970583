import React from "react";
import { useHistory } from "react-router-dom";
import { useMutation } from "@apollo/client";

import { UPDATE_GOAL } from "../../graphql/mutations";
import { ASSESSMENT_GOAL } from "../../graphql/queries";

import { OptionsBar, Form } from "../../components";
import { AppContext } from "../../context/AppContext";

const GoalImpact = (props) => {
	const { getContentByDocType } = React.useContext(AppContext);
	const history = useHistory();
	const { goal_id } = props.goal;

	const { page, common_translations } = getContentByDocType("goal_page");

	const [updateGoalFailed, setUpdateGoalFailed] = React.useState(false);
	const [updateGoalMsg, setUpdateGoalMsg] = React.useState("");
	const [impact, setImpact] = React.useState(props.goal.impact);
	const [motivation, setMotivation] = React.useState("");

	const [updateGoalMutation] = useMutation(UPDATE_GOAL);

	const updateGoal = async (e, formState) => {
		e.preventDefault();
		const { _id } = props.goal;
		const { motivation } = formState;
		const errors = common_translations?.error_messages[0] ?? null;

		setMotivation(motivation);

		if (motivation !== "" && impact !== null) {
			setUpdateGoalFailed(false);

			try {
				await updateGoalMutation({
					variables: {
						_id,
						input: {
							impact,
							motivation,
							saved: true,
						},
					},
					refetchQueries: [
						{ query: ASSESSMENT_GOAL, variables: { _id } },
					],
					awaitRefetchQueries: true,
				});

				history.goBack();
			} catch (error) {
				setUpdateGoalFailed(true);
				setUpdateGoalMsg(errors?.save_assessment ?? "");
			}
		} else {
			setUpdateGoalFailed(false);
			setUpdateGoalMsg(errors?.impact_validation ?? "");
		}
	};

	return (
		<>
			<div className="section">
				<h2 className="section__title section__title--centered section__title--lowercase">
					{page.impact_title ?? "How"} {goal_id}?
				</h2>

				{page.impact_options && (
					<OptionsBar
						options={page.impact_options.slice(0, 5)}
						otherOption={page.impact_options[5]}
						onChangeHandler={(impactRatingState) =>
							setImpact(impactRatingState)
						}
						initialState={props.goal.impact}
					/>
				)}
			</div>
			<div className="section">
				<div className="section__inner">
					<h2 className="section__title section__title--centered section__title--lowercase">
						{page.motivation_field_title ?? "Motivate why"}
					</h2>
					<Form
						initialState={props.goal}
						fields={[
							{
								type: "textarea",
								value: motivation,
								required: true,
								id: "motivation",
								placeholder:
									page.motivation_field_placeholder ??
									"Max 3 000 characters",
								maxlength: 3000,
							},
						]}
						success={!updateGoalFailed}
						error={updateGoalFailed}
						message={updateGoalMsg}
						submit={{
							label:
								page.save_impact_assessment_label ??
								"Save assessment",
							handler: (e, formState) => updateGoal(e, formState),
						}}
						modifier={`centered`}
					/>
				</div>
			</div>
		</>
	);
};

export default GoalImpact;
