import React from "react";
import { useLocation, useHistory } from "react-router-dom";

const ScrollToTop = (props) => {
	const location = useLocation();
	const history = useHistory();

	React.useEffect(() => {
		if (history.action !== "POP") {
			let timer = setTimeout(() => window.scrollTo(0, 0), 10);

			return () => {
				clearTimeout(timer);
			};
		}
	}, [location.pathname]);

	return props.children;
};

export default ScrollToTop;
