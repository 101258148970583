import React from "react";
import { useParams, useHistory } from "react-router-dom";
import { useMutation } from "@apollo/client";

import { UPDATE_GOAL } from "../../graphql/mutations";
import { SINGLE_ASSESSMENT } from "../../graphql/queries";
import { StickyBar, SortingControls, LoadingIndicator } from "../../components";
import { AppContext } from "../../context/AppContext";

const GoalRelevanceBar = (props) => {
	const { locale, appData, getContentByDocType } = React.useContext(
		AppContext
	);
	const { common_translations } = getContentByDocType();
	const history = useHistory();
	const { user_id } = appData.user;
	const errors = common_translations?.error_messages[0] ?? null;
	const { _id, url_alias, goal_id } = useParams();

	const navigateToNextGoal = () => {
		const { goals, url_alias } = props.assessment;
		const nextGoalId = parseInt(goal_id) + 1;
		let nextGoalDatabaseId;

		goals.forEach((goal) => {
			if (goal.goal_id == nextGoalId) {
				nextGoalDatabaseId = goal._id;
			}
		});

		const nextGoalHref =
			nextGoalId > 17
				? `/${locale}/tool/assessment/${url_alias}`
				: `/${locale}/tool/assessment/${url_alias}/sort/${nextGoalDatabaseId}/${nextGoalId}`;

		history.push(nextGoalHref);
	};

	const [updateGoalMutation, { data, loading, error }] = useMutation(
		UPDATE_GOAL,
		{
			refetchQueries: [
				{
					query: SINGLE_ASSESSMENT,
					variables: { user_id, url_alias },
				},
			],
			awaitRefetchQueries: true,
			onCompleted: () => navigateToNextGoal(),
		}
	);

	return (
		<StickyBar>
			<>
				{loading && <LoadingIndicator />}

				<SortingControls
					onClick={(e, relevance) =>
						updateGoalMutation({
							variables: {
								_id,
								input: {
									relevance,
								},
							},
						})
					}
					disabled={loading}
					message={errors.save_assessment}
					status={error ? "error" : ""}
				/>
			</>
		</StickyBar>
	);
};

export default GoalRelevanceBar;
