import React from "react";

const ESCKEY = 27;

const Dropdown = (props) => {
	const dropdown = React.useRef(null);
	const [open, setOpen] = React.useState(false);

	const handleKeyUp = (e) => {
		if (e.which === ESCKEY) {
			e.preventDefault();
			closeDropdown();
		}
	};

	const handleClickOutside = (e) => {
		const target = e.target;

		if (target.className !== "dropdown") {
			closeDropdown();
		}
	};

	const addEventListeners = () => {
		document.addEventListener("keyup", handleKeyUp);
		document.addEventListener("click", handleClickOutside);
	};

	const removeEventListeners = () => {
		document.removeEventListener("keyup", handleKeyUp);
		document.removeEventListener("click", handleClickOutside);
	};

	const openDropdown = () => {
		setOpen(true);
		addEventListeners();
	};

	const closeDropdown = () => {
		setOpen(false);
		removeEventListeners();
	};

	React.useEffect(() => {
		if (props.show) {
			openDropdown();
		} else {
			closeDropdown();
		}
	}, [props.show]);

	return (
		<div ref={dropdown} className={`dropdown`} aria-hidden={!open}>
			<div className={`dropdown__inner`}>{props.children}</div>
		</div>
	);
};

export default Dropdown;
