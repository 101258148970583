import React from "react";
import { Button, ButtonGroup } from "../../components";
import cx from "classnames";
import "./sorting-controls.scss";
import { AppContext } from "../../context/AppContext";

const SortingControls = (props) => {
	const { onClick, status, message } = props;
	const { getContentByDocType } = React.useContext(AppContext);

	const { page } = getContentByDocType("assessment_goals_page");

	return (
		<div className="sorting-controls">
			{page.goal_priorities && (
				<>
					<ButtonGroup>
						<Button
							label={page.goal_priorities[0].label}
							onClick={(e) => onClick(e, 1)}
							disabled={props.disabled}
						/>
						<Button
							label={page.goal_priorities[1].label}
							onClick={(e) => onClick(e, 2)}
							disabled={props.disabled}
						/>
					</ButtonGroup>

					<Button
						label={page.goal_priorities[2].label}
						color="gray"
						onClick={(e) => onClick(e, 3)}
						disabled={props.disabled}
					/>
				</>
			)}

			<div
				className={cx(
					`sorting-controls__status`,
					status && "sorting-controls__status--" + status
				)}
			>
				{message}
			</div>
		</div>
	);
};

export default SortingControls;
