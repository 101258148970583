import React from "react";
import "./options-bar.scss";

import { Option } from "../../components";

const OptionsBar = (props) => {
	const [currentOptionValue, setCurrentOptionValue] = React.useState(
		props.initialState !== undefined ? props.initialState : null
	);

	const setOption = (val) => {
		setCurrentOptionValue(val);

		props.onChangeHandler(val);
	};

	const renderOption = (option, type) => {
		let selected = currentOptionValue === option.value ? true : false;

		return (
			<div className="options-bar__option" key={option.value}>
				<Option
					{...option}
					selected={selected}
					type={type}
					clickHandler={() => setOption(option.value)}
				/>
			</div>
		);
	};

	return (
		<div className={`options-bar`}>
			<div className="options-bar__options">
				{props.options.map((option) => renderOption(option, "as-card"))}
			</div>

			{props.otherOption && (
				<div className="options-bar__other-option">
					{renderOption(props.otherOption, "")}
				</div>
			)}
		</div>
	);
};

export default OptionsBar;
