import React from "react";
import { linkResolver } from "../../utils/prismic";
import { RichText } from "prismic-reactjs";

import { AppContext } from "../../context/AppContext";
import {
	Grid,
	GridSorted,
	GoalCard,
	Button,
	Modal,
	StickyBar,
	AssessmentSummary,
	LoadingIndicator,
} from "../../components";
import { NotFoundPage } from "../../pages";

const AssessmentGoals = (props) => {
	const { locale, getContentByDocType } = React.useContext(AppContext);
	const [showModal, setShowModal] = React.useState(false);
	const [goalCards, setGoalCards] = React.useState(null);
	const sortedGoalsCount = props.assessment.goals.filter(
		(goal) => goal.relevance !== null
	).length;
	const sortModalLink = `/${locale}/tool/assessment/${props.assessment.url_alias}/sort/${props.assessment.goals[0]._id}/1`;

	const { page, goals } = getContentByDocType("assessment_goals_page");

	if (!page) return <NotFoundPage />;
	if (!goals) return <LoadingIndicator />;
	React.useEffect(() => {
		let modalTimer = null;

		const gc = goals
			? props.assessment.goals.map((goal, i) => {
					return (
						<GoalCard
							key={i}
							{...goal}
							href={`/${locale}/tool/assessment/${props.assessment.url_alias}/${goal._id}/${goal.goal_id}`}
							title={goals[i] && goals[i].title}
							logoUrl={goals[i].logo?.url}
							locales={{
								done:
									page?.goal_overview_labels[0]?.done ??
									"Done",
								edit:
									page?.goal_overview_labels[0]?.edit ??
									"Edit",
							}}
						/>
					);
			  })
			: [];

		setGoalCards(gc);

		if (sortedGoalsCount < 17) {
			modalTimer = setTimeout(() => {
				setShowModal(true);
			}, 2000);
		}

		return () => modalTimer && clearTimeout(modalTimer);
	}, []);

	const renderGoalCardGrid = () => {
		let gridComponent;

		if (sortedGoalsCount === 17) {
			gridComponent = <GridSorted items={goalCards} />;
		} else {
			gridComponent = <Grid items={goalCards} />;
		}

		return gridComponent;
	};

	return (
		<>
			{page.save_and_close && (
				<Button
					link={`/${locale}/tool/assessments`}
					size={`small`}
					label={page.save_and_close}
				/>
			)}
			<h2 className={`page__title`}>{props.assessment.title}</h2>
			{page.description && (
				<p className="page__preamble">
					{RichText.asText(page.description)}
				</p>
			)}

			{sortedGoalsCount !== null && goalCards && renderGoalCardGrid()}

			{page.goal_sorting_modal.length > 0 && (
				<Modal
					show={showModal}
					title={page.goal_sorting_modal[0].title}
					description={RichText.render(
						page.goal_sorting_modal[0].sorting_instructions,
						linkResolver
					)}
					preventClose={true}
					position="center"
				>
					<Button
						link={sortModalLink}
						label={page.goal_sorting_modal[0].button_label}
					/>
				</Modal>
			)}

			<StickyBar>
				{page.summary.length > 0 && (
					<AssessmentSummary
						description={page.summary[0].summary_description}
						summary={page.summary[0].count_suffix}
						goals={props.assessment.goals}
						buttonLabel={page.summary[0].button_label}
						buttonHref={`/${locale}/tool/assessment/${props.assessment.url_alias}/result`}
					/>
				)}
			</StickyBar>
		</>
	);
};

export default AssessmentGoals;
