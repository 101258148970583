import Prismic from "@prismicio/client";

const appDataParsed = JSON.parse(document.getElementById("appData").innerHTML);
const { prismic } = appDataParsed;

export const Client = Prismic.client(prismic.apiEndpoint || "", {
	accessToken: prismic.accessToken || "",
});

export const linkResolver = (doc) => {
	const { locale } = appDataParsed;
	if (doc.type === "standard_page") return `/${locale}/articles/${doc.uid}`;

	// Fallback for other types, in case new custom types get created
	return `/doc/${doc.id}`;
};
