import React, { Component } from "react";
import "./sticky-bar.scss";

const StickyBar = (props) => {
	const innerEl = React.useRef(null);
	const [divStyle, setDivStyle] = React.useState({ minHeight: null });

	const calculateStickyHeight = () => {
		setDivStyle({
			minHeight: innerEl.clientHeight,
		});
	};

	React.useEffect(() => {
		calculateStickyHeight();
		window.addEventListener("resize", calculateStickyHeight);

		return () =>
			window.removeEventListener("resize", calculateStickyHeight);
	}, [innerEl]);

	return (
		<div className={`sticky-bar`} style={divStyle}>
			<div className="sticky-bar__inner">
				<div className={`sticky-bar__content`} ref={innerEl}>
					{props.children}
				</div>
			</div>
		</div>
	);
};

export default StickyBar;
