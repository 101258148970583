import React from "react";
import { LinedHeader, Grid } from "../../components";
import "./grid-sorted.scss";
import { AppContext } from "../../context/AppContext";

const GridSorted = (props) => {
	const { getContentByDocType } = React.useContext(AppContext);
	const { page } = getContentByDocType("assessment_goals_page");
	const { items } = props;
	const { goal_priorities } = page;

	const sortedGoalCards = items.reduce((acc, item) => {
		const key = item.props.relevance - 1;
		if (!acc[key]) {
			acc[key] = { goalCards: [] };
		}

		acc[key].goalCards.push(item);
		return acc;
	}, []);

	return (
		<div className="grid-sorted">
			{goal_priorities.map((relevance, i) => {
				const { label, value } = relevance;

				return (
					<div key={i} className="grid-sorted__row">
						{sortedGoalCards &&
							sortedGoalCards[value - 1]?.goalCards && (
								<>
									<LinedHeader headertitle={label} />
									<Grid
										items={
											sortedGoalCards[value - 1].goalCards
										}
									/>
								</>
							)}
					</div>
				);
			})}
		</div>
	);
};

export default GridSorted;
